<template>
  <div v-if="text" class="text-right">
    <small> {{ Math.ceil((text.length + optOutLength) / 160) }} / {{ textCount }} </small>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    hasOptout: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    optOutLength() {
      return this.hasOptout ? 21 : 0
    },
    textCount() {
      let length =
          160 -
          (this.text.length +
              this.optOutLength -
              Math.trunc((this.text.length + this.optOutLength) / 160) * 160)

      return length === 160 ? 0 : length
    },
  },
}
</script>
