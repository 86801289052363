<template>
  <ValidationProvider v-slot="{ failed, errors }" :rules="rules" :name="name" :vid="vid">
    <div class="form-group" :style="{ 'margin-bottom': marginBottom }">
      <label v-if="label" class="form-control-label">
        {{ label }}
        <feather v-if="explain" style="vertical-align:bottom;margin-left:10px;cursor:pointer;" type="help-circle"
                 @click="handleExplain" />
        <span v-if="sublabel" class="text-danger">{{ sublabel }}</span>
      </label>
      <MergeFieldsGroup v-if="showMergefield" :show-redemption="showRedemption" :hide-expire-date="hideExpireDate"
                        :show-keyword="showKeyword" :show-contact-link="showContactLink" :hide-title="hideTitle"
                        :dropdown-size="dropdownSize" :hide-contact-dropdown="hideContactDropdown"
                        :hide-samcart-dropdown="hideSamcartDropdown" @selected="appendField" />
      <div v-if="!hideInput" class="input-group input-group-merge">
        <div v-if="icon" class="input-group-prepend">
          <span class="input-group-text">
            <feather :type="icon" class="align-middle icon-dual"></feather>
          </span>
        </div>
        <div v-if="addOptout" class="d-block w-100 textarea-wrapper" :class="{ 'is-invalid': failed }">
          <textarea ref="message" v-model="iValue" :rows="rows" :name="name" :placeholder="placeholder"
                    :readonly="readonly" :disabled="disabled" :autocomplete="autocomplete" class="form-control"
                    :accept="accept" @change="handleChanged"></textarea>
          <span class="d-block opt-out"><small><i>Reply STOP to opt out</i> (this is appended to this message)</small></span>
        </div>
        <textarea v-else ref="message" v-model="iValue" :rows="rows" :name="name" :placeholder="placeholder"
                  :readonly="readonly" :disabled="disabled" :autocomplete="autocomplete" class="form-control"
                  :class="{ 'is-invalid': failed }" :accept="accept" @change="handleChanged"></textarea>
        <b-form-invalid-feedback v-if="errors.length" :style="{ marginLeft: icon ? '48px' : '' }">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <template v-if="isActiveMms && !hideUpload">
            <button v-b-tooltip.hover title="Upload Attachments" type="button" class="btn btn-outline-dark btn-sm"
                    @click="showModal">
              <i class="uil uil-image"></i>
              <span v-if="imageLength"
                    class="badge position-absolute rounded-pill top-0 start-100 bg-primary text-white">
                {{ imageLength }}
              </span>
            </button>
          </template>
        </div>
        <div class="col-6">
          <SMSLength v-if="smsLength" :text="iValue" :has-optout="addOptout"/>
        </div>
      </div>
      <span v-if="help" class="help-block">
        <small>{{ help }}</small>
      </span>

    </div>

    <b-modal id="image-upload-modal" v-model="isShowModal" title="Image" size="lg"
             @shown="manuallyAddFile(object.images)">
      <p class="text-primary font-weight-bold">Maximum image size is 5MB</p>
      <vue-dropzone :id="vid" ref="dropzoneUpload" :options="dropzoneOptions" :use-custom-slot="true"
                    @vdropzone-sending="sendingFile" @vdropzone-removed-file="onFileRemoved" @vdropzone-complete="onComplete"
                    @vdropzone-error="onUploadFailed">
        <div class="dropzone-custom-content">
          <i class="h1 text-muted uil-cloud-upload"></i>
          <h3 class="dropzone-custom-title">
            Drag & Drop or Click To Choose A File To Upload.
          </h3>
        </div>
      </vue-dropzone>

      <template v-slot:modal-footer>
        <b-button v-if="imageLength" variant="danger" @click="removeAllFiles">
          Remove Files
        </b-button>
        <b-button v-if="imageLength" variant="primary" @click="save">
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationProvider>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SMSLength from './SMSLength';
import MergeFieldsGroup from './MergeFieldsGroup.vue';
export default {
  components: {
    SMSLength,
    vueDropzone: vue2Dropzone,
    MergeFieldsGroup
  },

  props: {
    rows: {
      required: false,
      type: Number,
      default: 2,
    },
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    hideInput: {
      type: [String, Boolean],
      default: false,
    },
    explain: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: String,
      default: '1rem',
    },
    smsLength: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
    },
    hideUpload: {
      type: Boolean,
      default: false,
    },

    showMergefield: {
      type: Boolean,
      default: false,
    },
    showRedemption: {
      type: Boolean,
      default: false,
    },
    hideExpireDate: {
      type: Boolean,
      default: false,
    },
    showKeyword: {
      type: Boolean,
      default: false,
    },
    showContactLink: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dropdownSize: {
      type: String,
      default: '',
    },
    hideContactDropdown: {
      type: Boolean,
      default: false,
    },
    hideSamcartDropdown: {
      type: Boolean,
      default: false,
    },
    addOptout: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      iValue: '',
      isShowModal: false,
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        // acceptedFiles:
        //   "image/jpeg, image/gif, image/png, audio/basic, audio/L24, audio/mp4, audio/mpeg ,audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf",
        url: `${process.env.VUE_APP_BASE_API}api/v1/uploads/image`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    }
  },

  computed: {
    imageLength() {
      if (this.object && this.object.images)
        return this.object.images.length
      return 0
    },

    user() {
      return this.$store.getters['auth/user']
    },

    business() {
      return this.user && this.user.business
    },

    isActiveMms() {
      return this.business && this.business.is_active_mms
    },
  },

  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.iValue) {
        this.iValue = newVal;
      }
    },

    iValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    }
  },

  mounted() {
    this.iValue = this.value
  },

  methods: {
    appendField(field) {
      this.$refs.message.focus()
      document.execCommand('insertText', false, field)
    },

    sendingFile() { },

    manuallyAddFile(files) {
      files.forEach((file) => {
        var url = file.path;
        this.$refs.dropzoneUpload.manuallyAddFile(file, url);
      });
    },

    removeAllFiles() {
      this.$refs.dropzoneUpload.removeAllFiles()
      this.object.images = []
    },

    onFileRemoved(file) {
    },

    onComplete(res) {
      if (res.status === "success") {
        this.object.images.push(JSON.parse(res.xhr.response)["data"])
      }
    },

    onUploadFailed(file, message) {
      if (message) {
        let content = message
        if (message.errors && message.errors.file) {
          content = message.errors.file[0]
        }
        this.$toast.open({
          message: content,
          type: 'error',
        })
      }
      this.$refs.dropzoneUpload.removeFile(file)
    },

    handleChanged() {
      this.$emit('changed', this.iValue)
    },

    handleExplain() {
      this.$emit('clickExplain', true)
    },

    showModal() {
      this.isShowModal = true
    },

    save() {
      this.isShowModal = false
    },
  },
}
</script>
